<script setup>
import { onMounted, ref} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios'

    const router = useRouter();
    const route = useRoute();

    const rfid = ref(route.query.rfid);

    const AWSname = ref("");
    const name = ref("");
    let img = ref("");
    
    onMounted(async () => {
        getProductTelemetry()
        getPosition()
    })

    const getProductTelemetry = async() => {
        await axios.get('https://thinginfo.rfid-solutions.no/thinginfo/getProduct?urn=' + rfid.value, {})
        .then((response) => {
            let productName = response.data.name;
            name.value = productName;
            productName = response.data.customerId + "-" + productName.replace(/\s/g, '');
            AWSname.value = productName
            img.value = `https://digital-link.s3.eu-west-1.amazonaws.com/rentalDemo/${AWSname.value}.png`

        }).catch(() => {
            router.push({ path: '/'})
      })
    }

    const getPosition = async() => {
        navigator.geolocation.getCurrentPosition(
            position => {
                axios.post('https://thinginfo.rfid-solutions.no/thinginfo/postLocation', {'urn': rfid.value, 'latitude': position.coords.latitude, 'longitude': position.coords.longitude}, {})
            },
            error => {
                console.log(error.message);
            },
        )
    }

  const goToReport = async() => {
    router.push({ path: '/report', query: {rfid: rfid.value}})
  }

  const goToDatasheet = async() => {
    if (AWSname.value !== "") {
        window.location.href = `https://digital-link.s3.eu-west-1.amazonaws.com/rentalDemo/${AWSname.value}.pdf`
    }
  }

</script>

<template>
    <q-page>
        <div class=center-screen>
            <div class="q-pa-md row items-start">
                <q-card class="bg-lightgrey text-black no-margin full-height">
                    <q-card-section>
                        <div class="text-h6">{{ name }} </div>
                    </q-card-section>
                    <q-card-section>
                        <q-btn @click="goToDatasheet"> Link to datasheet</q-btn>
                    </q-card-section>
                    <q-card-section>
                        <q-btn @click="goToReport">Report damage</q-btn>
                    </q-card-section>
                    <q-card-section>
                        <q-img
                        :src="img"
                        :ratio="1"
                        class="q-mt-md"
                        />
                    </q-card-section>
                    <!--
                    <q-card-section>
                        <q-btn
                            push
                            color="red"
                            label="Remove Image"
                            @click="handleImageButton">
                        </q-btn>
                    </q-card-section>
                    -->
                </q-card>
            </div>
        </div>
    </q-page>
</template>

<style>

    .center-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 75vh;
    }

</style>