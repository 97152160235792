import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/HomePage.vue'
import Product from '../views/ProductInfo.vue'
import Report from '../views/ReportPage.vue'
import Deleted from '../views/DeletedPage.vue'
import WurthProduct from '../views/WurthProductInfo.vue'

const routes = [
  {
    path: '/product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/wurth-product',
    name: 'Wurth-Product',
    component: WurthProduct,
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
  },

  {
    path: '/deleted',
    name: 'Deleted',
    component: Deleted,
  },
  {
    path: '/:catchAll(.*)',
    name: 'Home',
    component: Home,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router