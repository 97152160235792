<script setup>
    import { ref } from 'vue'
    import Camera from 'simple-vue-camera'
    import axios from 'axios'
    import { useRoute, useRouter } from 'vue-router'

    const router = useRouter();
    const route = useRoute();

    const damagedText = ref("")
    const camera = ref(null)
    const img = ref(null)
    const blob = ref(null)
    const base64img = ref(null)
    const rfid = ref(route.query.rfid);

    const takePicture = async() => {
        const cameraComponent = camera.value;
        blob.value = await cameraComponent.snapshot({ width: 300, height: 300});
        const url = URL.createObjectURL(blob.value);
        const reader = new FileReader();
        await reader.readAsDataURL(blob.value);
        reader.onloadend = async() => {
            base64img.value = reader.result
        }
        img.value = url
    }

    const resetImage = async() => {
        img.value = null;
        blob.value = null;
        base64img.value = null;
    }

    const save = async() => {
        await axios.post('https://thinginfo.rfid-solutions.no/thinginfo/addReport', {"image": base64img.value, "comment": damagedText.value, "urn": rfid.value}, {})
        .then(() => {
            console.log("Successfully uploaded")
        }).catch(() => {
            console.log("failed to upload")
        })
        
        resetImage();
        damagedText.value = "";
    }

    const goBack = async () => {
        router.push({ path: '/product', query: {rfid: rfid.value}});
    } 

</script>

<template>
    <q-page>
        <div class=center-screen>
            <div class="q-pa-md row items-start">
                <q-card class="bg-lightgrey text-black no-margin full-height">
                    <q-card-section>
                        <div class="text-h6" style="position: inherit;">
                            <div class="back-button">
                                <q-btn icon="arrow_back_ios" flat round @click="goBack"></q-btn>
                            </div>
                            Report damage 
                        </div>
                    </q-card-section>
                    <q-card-section>
                        <q-input
                            v-model="damagedText"
                            placeholder="What is damaged?"
                            filled
                            autogrow
                            ></q-input>
                    </q-card-section>
                    <q-card-section>
                        <div v-if="img === null">
                            <Camera ref="camera" :resolution="{width: 300, height: 300}" autplay>    
                            </Camera>
                            <q-btn
                                push
                                label="Take picture"
                                @click="takePicture">
                            </q-btn>
                        </div>
                        <div class="image-container" v-else>
                            <button class="x" type="button" @click="resetImage">x</button>
                            <img
                            :src="img"
                            :ratio="1"
                            style="width: 100%; height:100%;"
                            />
                        </div>
                        <div v-if="img !== null">
                            <q-btn
                            label="Save"
                            @click="save">
                            </q-btn>
                        </div>
                    </q-card-section>
                    <q-separator></q-separator>
                </q-card>
            </div>
        </div>
    </q-page>
</template>

<style>

    .center-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 75vh;
    }

    .image-container {
        position: relative;
        z-index: 1;
    }
    .image-container .x {
        position: absolute;
        right: 10px;
        z-index: 2;
    }

    .x {
        background: white;
    }
    
    .back-button {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

</style>