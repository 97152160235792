<script setup>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";
import axios from "axios";

const $q = useQuasar();

const router = useRouter();
const route = useRoute();

const rfid = ref(route.query.rfid);

const AWSname = ref("");
const name = ref("");
let img = ref("");

const iconRadio = ref(0);

const projectName = ref("");
const projectNumber = ref("");

onMounted(async () => {
  getProductTelemetry();
  getPosition();
});

const getProductTelemetry = async () => {
  await axios
    .get(
      "https://thinginfo.rfid-solutions.no/thinginfo/getProduct?urn=" +
        rfid.value,
      {}
    )
    .then((response) => {
      let productName = response.data.name;
      name.value = productName;
      productName =
        response.data.customerId + "-" + productName.replace(/\s/g, "");
      AWSname.value = productName;
      img.value = `https://digital-link.s3.eu-west-1.amazonaws.com/rentalDemo/${AWSname.value}.png`;
    })
    .catch(() => {
      router.push({ path: "/" });
    });
};

const getPosition = async () => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      axios.post(
        "https://thinginfo.rfid-solutions.no/thinginfo/postLocation",
        {
          urn: rfid.value,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        },
        {}
      );
    },
    (error) => {
      console.log(error.message);
    }
  );
};

const goToReport = async () => {
  router.push({ path: "/report", query: { rfid: rfid.value } });
};

const goToDatasheet = async () => {
  if (AWSname.value !== "") {
    window.location.href = `https://digital-link.s3.eu-west-1.amazonaws.com/rentalDemo/${AWSname.value}.pdf`;
  }
};

function onRadioChange(event) {
  if (event.target !== null) {
    iconRadio.value = parseInt(event.target.value);
  }
}

async function sendData() {
  const response = await axios.post(
    "https://thinginfo.rfid-solutions.no/thinginfo/postWurthData",
    {
      urn: rfid.value,
      projectName: projectName.value,
      projectNumber: projectNumber.value,
      icon: iconRadio.value,
    },
    {}
  );

  if (response.status === 200) {
    $q.notify({
      message: "Data has been sent!",
      color: "green",
    });

    projectName.value = "";
    projectNumber.value = "";
  } else {
    $q.notify({
      message: "Error sending data!",
      color: "red",
    });
  }
}
</script>

<template>
  <q-page>
    <div class="center-screen">
      <div class="q-pa-md row items-start">
        <q-card class="bg-lightgrey text-black no-margin full-height main-card">
          <q-card-section>
            <div class="text-h6">{{ name }}</div>
          </q-card-section>
          <q-card-section>
            <q-btn @click="goToDatasheet"> Link to datasheet</q-btn>
          </q-card-section>
          <q-card-section>
            <q-btn @click="goToReport">Report damage</q-btn>
          </q-card-section>

          <q-card-section>
            <q-input outlined v-model="projectName" label="Project name" />
          </q-card-section>

          <q-card-section>
            <q-input outlined v-model="projectNumber" label="Project number" />
          </q-card-section>

          <div class="selectable-icons">
            <input
              id="icon1"
              type="radio"
              name="icon"
              @input="onRadioChange"
              value="1"
            />
            <label for="icon1">
              <q-icon
                :name="iconRadio === 1 ? 'star' : 'star_border'"
                size="50px"
                :color="iconRadio === 1 ? 'primary' : 'black'"
              />
            </label>

            <input
              id="icon2"
              type="radio"
              name="icon"
              @input="onRadioChange"
              value="2"
            />
            <label for="icon2">
              <q-icon
                :name="iconRadio === 2 ? 'favorite' : 'favorite_border'"
                size="50px"
                :color="iconRadio === 2 ? 'primary' : 'black'"
              />
            </label>
          </div>

          <q-btn @click="sendData">Send</q-btn>

          <!--q-card-section>
                        <q-img
                        :src="img"
                        :ratio="1"
                        class="q-mt-md"
                        />
                    </q-card-section-->
          <!--
                    <q-card-section>
                        <q-btn
                            push
                            color="red"
                            label="Remove Image"
                            @click="handleImageButton">
                        </q-btn>
                    </q-card-section>
                    -->
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<style>
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 75vh;
}

.main-card {
  padding: 20px;
}

.selectable-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 15px;
}

.selectable-icons input[type="radio"] {
  display: none;
}

.selectable-icons .q-icon {
  cursor: pointer;
}
</style>
