<script setup>

</script>

<template>
  <q-page>
  </q-page>
</template>

<style>

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 75vh;
}

.information-container {
  display: flex;
  flex-wrap: nowrap;
}

.val-info, .string-info {
  flex: 1, 1, 100px;
  white-space: nowrap;
}

p, li
{
  font-size: 4vmin;
}
@media screen and (min-width: 1200px) {
  p, li {
    font-size: 25px;
  }  
}

#green {
  background: green;
}

</style>