import script from "./DeletedPage.vue?vue&type=script&setup=true&lang=js"
export * from "./DeletedPage.vue?vue&type=script&setup=true&lang=js"

import "./DeletedPage.vue?vue&type=style&index=0&id=73cc029f&lang=css"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCard,QCardSection,QInput,QBtn,QSeparator});
